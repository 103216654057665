.preloader {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  user-select: none;
}

.preloader.is-hidden {
  display: block;
  pointer-events: none;
}

.preloader__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.preloader__content {
  text-align: center;
}
