.preloader {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#fff, 1);
  // transition: opacity 0.6s
  user-select: none;
  &.is-hidden {
    display: block;
    pointer-events: none; }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px; }
  &__content {
    text-align: center; } }
